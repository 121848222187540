.funner-global-web-app-page body {
  overflow: hidden;
}

.funner-icon {
  position: relative;
  display: inline-block;

  img,
  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
  }
}

.funner-icon-svg {
  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
  }

  &--stroke {
    svg {
      stroke: currentColor;
    }
  }
  &--fill {
    svg {
      fill: currentColor;
    }
  }
}

[data-tippy-root] {
  z-index: 2100000001 !important;
  p {
    margin: 0;
  }
}
.tippy-content {
  padding: 5px 9px !important;
}

.checkbox-small-gray {
  // .checkbox-small-gray__input
  &__input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + .checkbox-small-gray__label .checkbox-small-gray__marker {
      border-color: transparent;
      svg,
      img {
        opacity: 1;
      }
    }
  }
  // .checkbox-small-gray__label
  &__label {
    display: flex;
    align-items: center;
    gap: 10px;
    user-select: none;
  }
  // .checkbox-small-gray__marker
  &__marker {
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
    border: 1px solid #2a38479e;
    border-radius: 3px;
    position: relative;

    img,
    svg {
      position: absolute;
      top: -1px;
      left: -1px;
      z-index: 2;
      display: block;
      width: 17px;
      height: 17px;
      object-fit: contain;
      opacity: 0;
    }
  }
  // .checkbox-small-gray__text
  &__text {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.15px;
  }
}

[hidden] {
  display: none !important;
}

.MuiColorInput-Popover {
  .MuiColorInput-HueSlider .MuiSlider-rail {
    transform: translateY(-50%) scale(-1, 1);
  }
}

.ps {
  position: relative;
}
.ps__rail-y {
  right: auto !important;
  left: 0 !important;
}

.notistack-SnackbarContainer {
  z-index: 2100000000000000001 !important;
  align-items: flex-start;

  .notistack-MuiContent > *:last-child {
    margin-right: auto;
    // padding-right: 16px;
    // margin-left: -8px;
    // padding-left: 0;
    padding-left: 16px;
    margin-right: -8px;
    padding-right: 0;
  }
}
body.rtl .notistack-SnackbarContainer .notistack-MuiContent > *:last-child {
  padding-right: 16px;
  margin-left: -8px;
  padding-left: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  // -webkit-text-fill-color: black !important;
}

input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus,
input:-moz-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  // -moz-text-fill-color: black !important;
}

.MuiDataGrid-footerContainer {
  justify-content: flex-start !important;
}
.MuiDataGrid-virtualScroller {
  contain: paint;
  will-change: transform;
}

.editor-container {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 200px;
}

.editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.toggle-fullscreen-btn {
  background: none;
  border: none;
  color: #1976d2;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 2px 4px;
}

.editor-error {
  color: red;
  padding: 4px 8px;
  font-size: 0.85rem;
}

.editor-container.full-screen {
  position: fixed;
  top: 2.5%;
  left: 2.5%;
  width: 95%;
  height: 95%;
  background-color: #fff;
  z-index: 9999;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
}

.editor-container.full-screen .react-codemirror2 {
  height: 100%;
}
.editor-container.full-screen .CodeMirror {
  height: 100%;
}

.CodeMirror {
  height: 200px;
}
