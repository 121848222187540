// .root-whatsapp {
.priority-documents-popup {
  &:not(.priority-documents-popup--no-style) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: toRem(30) toRem(10);
    transition: visibility 0.8s ease 0s;
    overflow: auto;
    z-index: 2100000202;
    background-color: rgba(33, 37, 41, 0.15);
  }

  &--anim {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.5s ease 0s, visibility 0.5s ease 0s;
    &.visible {
      opacity: 1;
      visibility: visible;
      pointer-events: initial;
    }
  }

  * {
    box-sizing: border-box;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    background-color: #fff;
    width: 100%;
    max-width: 770px;
    padding: 70px 40px;
    position: relative;
    -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  &__close {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 24px;
    height: 24px;
    color: #788e97;
    font-size: 24px;
    cursor: pointer;

    svg {
      display: inline-block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      path {
        fill-opacity: 1 !important;
        fill: currentColor;
      }
    }

    &:hover {
      color: #00adee;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 26px;
    line-height: 157%;
    margin-bottom: 30px;
    font-weight: 700;
    letter-spacing: 1.5px;
    color: #212529;

    .funner-icon {
      width: 30px;
      height: 30px;
      flex: 0 0 30px;
      background-image: linear-gradient(
        45deg,
        #9773b3,
        rgba(151, 115, 179, 0.8)
      );
      border-radius: 3px;
      box-shadow: -10px 10px 20px 0 rgba(5, 57, 76, 0.1);
      border: 1px solid hsla(0, 0%, 100%, 0.8);
      img,
      svg {
        width: 24px;
        height: 24px;
        top: 3px;
        left: 3px;
      }
    }
  }

  &__body {
    min-height: 300px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &__doc-info {
    > * {
      margin-bottom: 30px;
    }
  }
  &__title {
    color: #212529;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 157%;
    letter-spacing: 1px;
  }
  &__target-text {
    color: #80939c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 128.571%;
    letter-spacing: 0.15px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__target-choose {
    display: flex;
    gap: 18px;
  }
  &__target-item-wrapper {
    width: calc(33.3333% - 12px);
    position: relative;
    flex: 0 0 calc(33.33333% - 12px);

    .form-get-token-popup__input {
      padding: 10px 14px !important;
      &::placeholder {
        color: #80939c !important;
      }
    }
  }

  // .priority-documents-popup__buttons-row
  &__buttons-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex: 1 1 auto;
  }
  // .priority-documents-popup__btn-wrapper
  &__btn-wrapper {
  }
  // .priority-documents-popup__button
  &__button {
    // display: flex;
    // flex-direction: column;
    // background-color: transparent;
    cursor: pointer;
    height: 124px;
    width: 130px;
    border: 1px solid #bfd0d7;
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    gap: 5px;
    padding: 23px 8px 0;
    // justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #fff;
    color: #788e97;
    position: relative;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      background-color: #e5f6fd;
      border-color: transparent;
    }
    &._checked {
      border-width: 2px !important;
      border-color: #00adee !important;
    }
    &:focus {
      border-width: 2px !important;
      border-color: #00aeee88 !important;
    }
  }
  // .priority-documents-popup__icon
  &__icon {
    width: 36px;
    height: 36px;
    flex: 0 0 36px;
  }

  &__button-title {
    color: #80939c;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.15px;
  }
  // .priority-documents-popup__button-text
  &__button-text {
    color: #788e97;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
  }

  &__preview {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2147483642;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: auto;
    justify-content: center;
    align-items: center;

    .priority-documents-popup__content {
      max-height: 100%;
    }
    .priority-documents-popup__body {
      max-height: 100%;
      display: flex;
      flex-direction: column;
    }

    .funner-templates-menu {
      max-height: calc(75vh - 35px);
      margin-bottom: 20px;
    }
  }

  &__choose-template {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2147483642;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: auto;
    justify-content: center;
    align-items: center;
  }

  &__images-container {
    max-height: 100%;
    overflow: auto;
    flex: 1 1 auto;
    padding: 20px 0;
    a {
      display: block;
      margin: 0 auto;
      height: auto;
      // max-width: 400px;
      cursor: zoom-in;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    img {
      height: auto;
      max-width: 100%;
      min-height: 200px;
      object-fit: contain;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    }
  }
}
// }

.gray-radio-list {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    > label {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;

        &:checked ~ .gray-radio-list__radio-marker {
          &::after {
            transform: scale(1);
          }
        }
      }
    }

    .switch-container {
      width: 100%;
    }
    .about-tab-assing-call-wrapper {
      flex: 1 1 auto;
    }
    .about-tab__switch-container-input-wrapper {
      flex: 0 0 100%;
    }
  }
  &__radio-marker {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.54);
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: scale(0);
      background-color: rgba(0, 0, 0, 0.54);
      transition: transform 0.2s ease 0s;
    }
  }

  &__text {
    color: #80939c;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 157%;
    letter-spacing: 0.1px;
  }
}

.funner-priority-btn {
  display: inline-flex;
  width: auto;
  min-width: 120px;
  padding: 0 15px;
  line-height: 16px;
  height: 40px;
  margin: 0;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 3px;
  cursor: pointer;
  // box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
  //   0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  color: #fff;
  border: 1px solid #00adee;

  &--fill {
    background-color: #00adee;
    color: #fff;
    // &:focus {
    //   border-color: #00b4f6;
    //   border-width: 2px;
    // }
  }
  &--outline {
    background-color: #fff;
    color: #00adee;
    // &:focus {
    //   border-color: rgba(0, 173, 238, 0.7);
    //   border-width: 2px;
    // }
  }

  &[disabled] {
    opacity: 0.65;
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    background-image: linear-gradient(110deg, #00adee, rgba(0, 173, 238, 0.7));
    background-color: rgba(0, 173, 238, 0.7);
    color: #fff;
  }
  &:focus {
    box-shadow: 0 0 5px rgba(0, 173, 238, 0.7);
  }
}

.funner-priority-checkbox {
  position: relative;
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;

    &:checked
      + .funner-priority-checkbox__label
      .funner-priority-checkbox__check-icon
      svg,
    + .funner-priority-checkbox__label
      .funner-priority-checkbox__check-icon
      img {
      opacity: 1;
    }
  }

  &__label {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    user-select: none;

    &:hover {
      .funner-priority-checkbox__check-icon {
        border-color: #00adee;
      }
    }
    &:focus {
      .funner-priority-checkbox__check-icon {
        border-color: #00adee;
      }
    }
  }

  &__check-icon {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    display: inline-block;
    border-radius: 3px;
    position: relative;
    border: 2px solid rgba(120, 142, 151, 0.3);

    svg,
    img {
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      opacity: 0;
      object-fit: contain;
    }
  }

  &__text {
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.15px;
  }
}

.priority-documents-popup {
  .doc-popover {
    flex-direction: row;
    justify-content: space-between;

    &__text-col {
      flex: 0 0 percent(720, 1120);
      width: percent(720, 1120);
    }
    &__doc-col {
      flex: 0 0 percent(390, 1120);
      width: percent(390, 1120);
    }

    &__list-item {
      cursor: pointer;
      transition: background-color 0.3s ease 0s;

      &:hover {
        background-color: rgba(25, 118, 210, 0.08);
      }
    }
  }
}
