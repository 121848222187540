.funner-chat-bot-management {
  position: relative;
  z-index: 10000000;

  &__toggle-btn {
    color: #fff;
    text-align: center;
    font-size: 12px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6.5px 8px;
    border-radius: 4px;
    border: 1px solid #fff;
    cursor: pointer;

    &.active {
      background-color: #fff;
      color: var(--chat-main-color);
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 250px;

    .funner-global-web-app-page & {
      right: 0;
      left: auto;
    }

    &-content {
      background-color: #fff;
      width: 100%;
      // max-height: 200px;
      // overflow: auto;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      padding: 10px;
    }
  }

  &__builders-list {
    max-height: 200px;
    overflow: auto;
    overflow-x: hidden;
    margin: 0 -10px;
  }
  &__builder {
    padding: 5px 10px;
    background-color: #fff;
    transition: background-color 0.3s ease 0s;
    cursor: pointer;
    &:hover {
      background-color: #f7f7f7;
    }
  }

  &__go-back {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }
}
