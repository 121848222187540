//Burger Icon
.funner-icon-menu {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: block;
  position: relative;
  width: toRem(26);
  height: toRem(16);
  outline: none;
  background-color: transparent;
  border: 0;
  margin: 0 auto;
  cursor: pointer;
  z-index: 5;
  @media (any-hover: none) {
    cursor: default;
  }
  span,
  &::before,
  &::after {
    content: "";
    transition: all 0.3s ease 0s;
    right: 0;
    position: absolute;
    width: 100%;
    height: toRem(2);
    background-color: #fff;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
  span {
    top: 50%;
    transform: translate(0, -50%);
  }
}

.nav-open {
  .funner-icon-menu {
    span {
      width: 0;
    }
    &::before,
    &::after {
    }
    &::before {
      top: 50%;
      transform: translate(0, -50%) rotate(-45deg);
    }
    &::after {
      bottom: 50%;
      transform: translate(0, 50%) rotate(45deg);
    }
  }
}
