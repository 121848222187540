.root-whatsapp {
  .media-popup-preview {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: toRem(30) toRem(10);
    transition: visibility 0.8s ease 0s;
    overflow: auto;
    z-index: 2147483641;
    background-color: rgba(33, 37, 41, 0.15);
    * {
      box-sizing: border-box;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      // min-height: 100%;
      height: 100%;
      flex: 1 1 auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__content {
      background-color: #fff;
      width: auto;
      max-width: 815px;
      padding: 70px 40px;
      position: relative;
      -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      max-height: 100%;
    }

    &__close {
      position: absolute;
      top: 24px;
      left: 24px;
      width: 24px;
      height: 24px;
      color: #788e97;
      font-size: 24px;
      cursor: pointer;

      svg {
        display: inline-block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        path {
          fill-opacity: 1 !important;
          fill: currentColor;
        }
      }

      &:hover {
        color: #00adee;
      }
    }
    &__body {
      // min-height: 300px;
      // position: relative;
      max-height: 100%;
    }

    &__media-container {
      max-width: 100%;
      max-height: 100%;
      text-align: center;

      // display: inline-block;
      > * {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
