.priority-send-docs {
  .priority-documents-popup__content {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .default-funner-table {
    tbody {
      display: block;
      // max-height: 35vh;
      height: 35vh;
      overflow: auto;
    }
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
    }
    thead {
      width: calc(100% - 17px);
      position: static;
    }

    thead th {
      padding: 6px 16px;
    }
    tbody td {
      padding: 14px 16px;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #00000099;
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }
  &--web {
    .priority-send-docs__search {
      padding-left: 24px;
      display: flex;
      gap: 18px;
      padding: 4px 0;
      justify-content: space-between;
    }
    .priority-send-docs__search-item {
      // flex: 0 0 calc(16.66666% - 15px);
      // width: calc(16.66666% - 15px);
      // flex: 0 0 calc(20% - 4px);
      // width: calc(20% - 4px);
      flex: 0 0 calc(50% - 78px);
      width: calc(50% - 78px);

      .form-get-token-popup__input {
        padding: 8px 14px !important;
      }
    }
  }
}
