.navigation-settings-popup {
  width: 52px;
  flex: 0 0 52px;
  // overflow-x: hidden;
  // overflow-y: auto;
  pointer-events: initial;
  position: relative;
  box-sizing: border-box;
  transition: width 0.3s ease 0s, flex 0.3s ease 0s;

  // &::-webkit-scrollbar {
  //   display: none;
  // }

  .ps__rail-y {
    right: auto !important;
    left: 0 !important;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  button {
    cursor: pointer;
  }
  a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
  }
  ul li {
    list-style: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }
  // .navigation-settings-popup__block
  &__block {
  }
  // .navigation-settings-popup__block-title
  &__block-title {
    // color: #fff;
    color: var(--chat-main-color);
    font-size: 17px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    display: none;
    white-space: nowrap;

    // margin-bottom: 18px;
    // padding: 0 20px;

    @media (max-width: 767.98px) {
      font-size: 16px !important;
      margin-bottom: 16px;
    }
  }
  // .navigation-settings-popup__menu-items
  &__menu-items {
  }
  &__item {
  }
  &__link {
    appearance: none !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    padding: 9px 16px;
    border: 0;
    letter-spacing: 0.3px;
    font-size: 14px;
    line-height: 1.7;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 600;
    cursor: pointer;
    background-color: transparent;
    width: 100%;
    color: rgba(0, 0, 0, 0.6);
    position: relative;
    font-family: "Arimo";
    transition: font-weight 0.3s ease 0s, color 0.3s ease 0s,
      letter-spacing 0.3s ease 0s;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;

    &::after {
      content: "";
      position: absolute;
      width: 2px;
      height: 100%;
      right: 0px;
      top: 0px;
      transform-origin: center bottom;
      transform: scaleY(0);
      background-color: var(--chat-main-color);
      transition: transform 0.3s ease 0s;
    }

    &-icon {
      width: toRem(24);
      flex: 0 0 toRem(24);
      display: flex;
      svg {
        transition: fill 0.3s ease 0s, stroke 0.3s ease 0s;
      }
    }
    &-text {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: opacity 0.3s ease 0s, visibility 0.3s ease 0s;
    }

    &:hover {
      color: rgba(0, 0, 0, 1);
      .navigation-settings-popup__link-icon {
        .funner-icon-svg--fill svg {
          fill: rgba(0, 0, 0, 1);
        }
        .funner-icon-svg--stroke svg {
          stroke: rgba(0, 0, 0, 1);
        }
      }
    }
    &.active-tab {
      color: var(--chat-main-color);
      font-weight: 600;
      .navigation-settings-popup__link-icon {
        .funner-icon-svg--fill svg {
          fill: var(--chat-main-color);
        }
        .funner-icon-svg--stroke svg {
          stroke: var(--chat-main-color);
        }
      }
      &::after {
        transform-origin: center top;
        transform: scaleY(1);
      }
    }
  }
}
.main-popup-tabs__toggle-nav {
  &-wrapper {
    position: absolute;
    // background-color: var(--chat-main-color);
    padding: 26px 15px;
    // right: 0;
    left: 0;
    box-sizing: border-box;
    top: 0;
    width: 61px;
    height: 71px;
    // cursor: pointer;
    border: none;
    margin: 0;
    pointer-events: initial;
    z-index: 10001;
    display: none;
    transition: width 0.3s ease 0s;

    .nav-open & {
      width: 220px;
    }
  }

  position: relative !important;
  width: 31px !important;
  top: 0 !important;
  right: 0 !important;
  height: 100% !important;
  img,
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }

  @media (max-width: 767.98px) {
    &-wrapper {
      height: 54px;
      padding: 15px;
      width: 54px;
      top: 5px;
      // background-color: white;
      transition: background-color 0s ease 0.5s;
      .nav-open & {
        width: 220px;
        // background-color: var(--chat-main-color) !important;
        background-color: transparent;
        transition: background-color 0s ease 0s;
      }
    }

    width: 24px !important;
  }
}

body.rtl .main-popup-tabs__toggle-nav {
  &-wrapper {
    right: 0;
    left: auto;
  }
}

.funner-global-web-app-page {
  .navigation-settings-popup {
    background-color: var(--unread-popup-main-color);
    width: 61px;
    flex: 0 0 61px;
    // overflow-x: hidden;
    // overflow-y: auto;
    pointer-events: initial;
    padding-top: 100px;
    transition: width 0.3s ease 0s, flex 0.3s ease 0s;

    &::-webkit-scrollbar {
      display: none;
    }

    &__block-title {
      color: #fff;
    }

    &__link {
      color: #fff;
      padding-right: 20px;
      transition: padding-right 0.3s ease 0s;

      &::after {
        display: none !important;
      }

      &-icon {
        svg {
          transition: none;
        }
      }

      &:not(.active-tab):hover {
        color: #fff;
        .navigation-settings-popup__link-icon {
          .funner-icon-svg--fill svg {
            fill: #fff;
          }
          .funner-icon-svg--stroke svg {
            stroke: #fff;
          }
        }
      }

      &.active-tab {
        background-color: #fff;
        .navigation-settings-popup__link-text {
          color: var(--unread-popup-main-color);
        }
      }
    }
  }

  .main-popup-tabs__toggle-nav {
    &-wrapper {
      display: block;
    }
  }
}

.nav-open {
  .navigation-settings-popup {
    width: 196px;
    flex: 0 0 196px;

    &__block-title {
      display: block;
    }
    &__block {
      &:not(:last-child) {
        margin-bottom: 30px;
      }

      @media (max-width: 767.98px) {
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }

    &__link {
      &-text {
        opacity: 1;
        visibility: visible;
        pointer-events: initial;
      }
    }
  }
}
.funner-global-web-app-page .nav-open {
  .navigation-settings-popup {
    width: 220px;
    flex: 0 0 220px;
    &__block {
      // &:not(:last-child) {
      //   margin-bottom: 65px;
      // }

      @media (max-width: 767.98px) {
        &:not(:last-child) {
          margin-bottom: 35px;
        }
      }
    }
  }
}
