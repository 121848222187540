.funner-templates-menu {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  *::before,
  *::after {
    display: inline-block;
  }
  ul li {
    list-style: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  &__templates-list {
    flex: 1 1 auto;
    padding: 10px 7px !important;
    overflow: auto;
    position: relative;
    margin: 0;
  }
  &__templates-list-item {
    &:not(:last-child) {
      margin-bottom: 10px !important;
    }
  }
}

.single-funner-template {
  &__content {
    padding: 12px !important;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    position: relative;

    &--active {
      background-color: #f0f0f0;
    }
  }

  &__header {
    display: flex;
    gap: 15px;
    align-items: center;
    max-width: 100%;
  }

  &__name-wrapper {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &__name {
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    letter-spacing: 0.15px;
    max-width: 200px;
  }

  &__icons-row {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  &__icon {
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
  }

  &__text-preview {
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 139.5%;
    letter-spacing: 0.4px;
  }

  .preview-content-manage-templates {
    margin-right: 0 !important;
  }

  .template-chat-sidebar__template-msg {
    margin-bottom: 10px !important;
  }
}

.template-chat-sidebar {
  padding: 0 !important;
  &__list {
    padding: 0;
  }
}

.user-info__sidebar-filter-wrapper {
  position: relative;
  display: inline-block;
  max-width: max-content;
}
.sidebar-filters-popup {
  position: absolute;
  top: 4px;
  left: calc(100% + 7px);
  z-index: 100000;

  &__content {
    border-radius: 4px !important;
    border: 1px solid #2a3847 !important;
    border-right: 2px solid #2a3847 !important;
    border-bottom: 2px solid #2a3847 !important;
    background: #fff !important;
    display: flex;
    padding: 11px 27px !important;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    min-width: 320px;
  }

  &__header {
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 143%;
    white-space: nowrap;
    letter-spacing: 0.17px;
  }

  &__filters {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  &__filter {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 100%;
  }
  &__filter-label {
    flex: 0 0 150px;
    width: 150px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
  }
  &__select {
    flex: 1 1 auto;
  }
}

.implemented-variables-list {
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  z-index: 100;

  padding: 8px 0 !important;
  .create-new-task-messaging__variable {
    transition: background-color 0.3s ease 0s;
    background-color: #fff;
    padding: 5px 10px !important;
    cursor: pointer;
    &:first-child {
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      border-radius: 0 0 4px 4px;
    }

    &:hover {
      background-color: #f7f7f7;
    }
  }
}
