.chat-users-fireberry-sync {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  &__title {
    color: #202020;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.15px;
  }

  &__text {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.15px;
    min-height: 2.8em;
  }

  &__sel-wrapper {
    max-width: 324px;
    width: 100%;
    z-index: 5;
  }

  &__buttons-row {
    display: flex;
    gap: 42px;
    width: 100%;
    margin-top: 52px;
    margin-bottom: 47px;
  }
  .popup__blue-btn {
    margin: 0 !important;
    font-weight: 600;
  }
}

.chat-users-fireberry-sync-popup {
  .priority-documents-popup {
    &__content {
      max-width: 653px;
      padding: 71px 36px 60px;
      background: #f5f5f5;
    }
  }
}
