.priority-sync {
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  // .priority-sync__title
  &__title {
  }
  // .priority-sync__tabs
  &__tabs {
  }
  // .priority-sync__navigation
  &__navigation {
  }
  &__tabs-body {
    padding-top: 20px;
  }

  &__tokens-api {
    padding: 20px;

    &-row {
      display: flex;
      gap: 16px;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    &-col {
      width: calc(50% - 8px);
      flex: 0 0 calc(50% - 8px);
      &--100 {
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}

.sync-tabs-navigation {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  // .sync-tabs-navigation__nav-button
  &__nav-button {
    display: flex;
    align-items: center;
    gap: 6px;
    position: relative;
    color: #0000008a;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    padding: 9px 16px;
    cursor: pointer;

    .default-funner-icon {
      height: 24px;
      width: 24px;
      flex: 0 0 24px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      right: 0;
      background-color: #1976d2;
      opacity: 0;
      visibility: hidden;
    }

    &._active-tab {
      color: #1976d2;
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
